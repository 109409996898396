<template>
  <b-card>
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedPasswordsLocal)">

        <b-row>

          <!-- old password -->
          <b-col md="6">
            <b-form-group
                :label="capitalize($t('oldPassword'))"
                label-for="account-old-password"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('oldPassword')"
                  vid="Password"
                  rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      :placeholder="capitalize($t('oldPassword'))"
                  />
                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="passwordToggleIconOld"
                          @click.native="togglePasswordOldVisibility"/>

                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
                :label="capitalize($t('newPassword'))"
                label-for="account-new-password"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('newPassword')"
                  vid="Password"
                  rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      :placeholder="capitalize($t('newPassword'))"
                  />
                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="passwordToggleIconNew"
                          @click.native="togglePasswordNewVisibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
                :label="capitalize($t('confirmPassword'))"
                label-for="account-retype-new-password"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('confirmPassword')"
                  vid="Password"
                  rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      :placeholder="capitalize($t('confirmPassword'))"
                  />
                  <b-input-group-append is-text>
                    <icon class="cursor-pointer"
                          :icon="passwordToggleIconRetype"
                          @click.native="togglePasswordRetypeVisibility"/>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mt-1 float-right"
            >
              {{ $t('Edit') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'

import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const passwordValueOld = ref(null)
    const newPasswordValue = ref(null)
    const RetypePassword = ref(null)
    const passwordFieldTypeOld = ref('password')
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const passwordToggleIconOld = computed(() => {
      return passwordFieldTypeOld.value === 'password' ? 'eye' : 'eye-slash'
    })
    const passwordToggleIconNew = computed(() => {
      return passwordFieldTypeNew.value === 'password' ? 'eye' : 'eye-slash'
    })
    const passwordToggleIconRetype = computed(() => {
      return passwordFieldTypeRetype.value === 'password' ? 'eye' : 'eye-slash'
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const togglePasswordOldVisibility = () => {
      passwordFieldTypeOld.value = passwordFieldTypeOld.value === 'password' ? 'text' : 'password'
    }
    const togglePasswordNewVisibility = () => {
      passwordFieldTypeNew.value = passwordFieldTypeNew.value === 'password' ? 'text' : 'password'
    }
    const togglePasswordRetypeVisibility = () => {
      passwordFieldTypeRetype.value = passwordFieldTypeRetype.value === 'password' ? 'text' : 'password'
    }

    const submitValidatedPasswordsLocal = () => {
      store.dispatch('auth/changePassord', {
        'currentPassword': passwordValueOld.value,
        'plainPassword': newPasswordValue.value
      }).then(response => {
        // console.log(response)
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      required,
      password,
      capitalize,

      // Data
      passwordValueOld,
      newPasswordValue,
      RetypePassword,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,

      // Computed
      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,

      // Methods
      togglePasswordOldVisibility,
      togglePasswordNewVisibility,
      togglePasswordRetypeVisibility,
      submitValidatedPasswordsLocal,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>