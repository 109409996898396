import store from '@/store'

export const useEmployees = () => {

  const submitValidatedEmployee = (employee) => {
    return new Promise((resolve, reject) => {
      if ('id' in employee) {
        updateEmployee(employee)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewEmployee(employee)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewEmployee = (employee) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user/addEmployee', employee)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateEmployee = (employee) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user/updateUser', employee)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeEmployee = (employee) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user/removeUser', employee)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // const getEmployeeIconStatus = (employee) => {
  //   if (getEmployeeStatus(employee) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
  //   else if (getEmployeeStatus(employee) == 'invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }
  //   else return { variant: 'primary', icon: 'paper-plane' }
  // }

  return {
    submitValidatedEmployee,
    removeEmployee,
    // getEmployeeIconStatus
  }
}