import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useEmployees } from './useEmployees'
import { capitalize } from '@/utils/filter'

import useAPI from '@/utils/useAPI'
import router from '@/router'

export const mixinEmployees = {
  components: {},
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newEmployee = ref({})
    const newEmployeeModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchUsers,
    } = useAPI()

    const {
      submitValidatedEmployee,
      removeEmployee,
    } = useEmployees()

    const addEmployee = () => {
      // console.log("addEmployee")
      newEmployeeModalShow.value = true
    }

    const submitValidatedEmployeeLocal = (employee) => {
      submitValidatedEmployee(employee)
        .then(response => {
          newEmployeeModalShow.value = false
          // console.log(response)
        })
    }

    const removeEmployeeLocal = (employee) => {
      removeEmployee(employee)
        .then(response => {
          // router.push({ name: 'Employees' })
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchUsers()

    return {
      // Components
      capitalize,

      // Data
      newEmployee,
      newEmployeeModalShow,

      // Computed

      // Methods
      addEmployee,
      submitValidatedEmployeeLocal,
      removeEmployeeLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteEmployeeAlert (employee) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEmployee') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeEmployeeLocal(employee)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}